import * as React from 'react'
import styled, { keyframes } from 'styled-components'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import { media } from '../../styles/styleUtils'

export const Parrafo = styled.p`
  text-align: justify;
  font-size: 1.2rem;
  margin: 2rem 0 2rem 0;
`
const scale = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.5);
  }
`

const ImagenQuienesSomos = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  ${media.md`
  `}
`

const TituloSeccion = styled.h3`
  position: absolute;
  color: #683b11;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  z-index: 2;
  ${media.sm`
    width: 79px;
    font-size: 1rem;
  `}
`
export const WrapperBody = styled.div`
  display: block;
  margin-top: 2rem;
  ${media.md`
    margin: 1rem;
  `}
`

export const CabeceraLaCarta = styled.div`
  display: flex;
  justify-content: space-around;
  ${media.md`
    margin: 1rem;
  `}
`
export const ColumnaCabeceraCarta = styled.div`
  flex-direction: column;
  text-align: left;
  background-color: #282b2e;
  color: white;
  padding: 2rem;
  width: 30%;
  max-height: 21rem;
  ${media.md`
  `}
`
const WrapperImageCabeceraDerecha = styled.div`
  position: relative;
  justify-content: center;

  width: 100%;
  overflow: hidden;
  text-align: center;
  ${media.md`
  `}
`

const TipoMenu = styled.div`
  column-count: 1;
`
const Lista = styled.ul`
  margin-top: 1rem;
  list-style: circle;
  li {
    margin-left: 1rem;
  }
`
const ParrafoCentral = styled.p`
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-style: italic;
`

const Link = styled.a`
  font-weight: bold;
`

export const MenusEspeciales: React.FC = () => {
  const ImageQueryMenusEspeciales = useStaticQuery(graphql`
    query ImageQueryMenusEspeciales {
      QuienesSomos: file(
        relativePath: { eq: "imagenes/menus-cabecera-menus.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <>
      <WrapperImageCabeceraDerecha id="menus-especiales">
        <TituloSeccion>Menús especiales</TituloSeccion>
        <ImagenQuienesSomos
          alt={'Plato de espárragos rellenos'}
          fluid={ImageQueryMenusEspeciales.QuienesSomos.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="20% 20%"
        ></ImagenQuienesSomos>
      </WrapperImageCabeceraDerecha>
      <TipoMenu>
        <ParrafoCentral>
          El restaurante Borrasco es ideal para llevar a cabo cualquier tipo de
          reunión o celebración en un ambiente tranquilo y grato disfrutando de
          nuestras especialidades. Para estas ocasiones especiales, elaboramos
          menús personalizados en función de los gustos y necesidades de
          nuestros clientes.
        </ParrafoCentral>
        <ParrafoCentral>
          Ven y comprueba como restaurante Borrasco es una opción muy
          recomendable si lo que buscas es un restaurante para grupos en Lerín.
        </ParrafoCentral>
        <ParrafoCentral>
          Para solicitar información ampliada sobre la atención a grupos en
          Restaurante Borrasco contacte con nosotros en{' '}
          <Link href="mailto:info@restauranteborrasco.com">
            info@restauranteborrasco.com
          </Link>{' '}
          o en el teléfono <Link href="tel:+34948530561">948530561 </Link>{' '}
        </ParrafoCentral>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </TipoMenu>
    </>
  )
}

import * as React from 'react'
import { Wrapper } from '../layout'
import theme from '../styles/theme'
import GlobalStyles from '../styles/globalStyles'
import Head from '../components/Head'
import styled, { ThemeProvider } from 'styled-components'
import { BarraNavegacion } from '../components/BarraNavegacion'
import { media } from '../styles/styleUtils'
import { PieDePagina } from '../components/PieDePagina'
import * as Scroll from 'react-scroll'
import { animateScroll as scroll } from 'react-scroll'
import { MenuFinDeSemana } from '../components/menus/MenuFinDeSemana'
import { MenuDegustacion } from '../components/menus/MenuDegustacion'
import { MenuDelDia } from '../components/menus/MenuDelDia'
import { MenusEspeciales } from '../components/menus/MenusEsepeciales'
const Element = Scroll.Element
const scroller = Scroll.scroller
interface IndexPageProps {
  location: {
    pathname: string
  }
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
      }
    }
  }
}

export const Parrafo = styled.p`
  text-align: justify;
  font-size: 1.2rem;
  margin: 2rem 0 2rem 0;
`

export const WrapperBody = styled.div`
  display: block;
  justify-content: center;
  margin-top: 2rem;
  ${media.md`
    margin: 1rem;
  `}
`

export const CabeceraLaCarta = styled.div`
  display: flex;
  justify-content: space-around;
  ${media.md`
    margin: 1rem;
  `}
`
export const ColumnaCabeceraCarta = styled.div`
  flex-direction: column;

  width: 33%;
  z-index: 2;
  max-height: 21rem;
  ${media.md`
  display:none;
  `}
`

const TituloMenu = styled.h2`
  font-weight: bold;
  margin-bottom: 1rem;
`
const Lista = styled.ul`
  list-style: circle;
  li {
    margin-left: 1rem;
  }
`
const ParrafoCentral = styled.p`
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-style: italic;
`

const TipoMenu = styled.div`
  column-count: 1;
  @media (min-width: 768px) {
    column-count: 3;
  }
`

const CajaDerecha = styled.div`
  width: 66%;
  height: 100%;
  flex-direction: column;
  ${media.md`
    width: 100%;
  `};
`
const ContenedorLeft = styled.div`
  position: fixed;
  background-color: #282b2e;
  color: white;
  text-align: left;
  padding: 2rem;
  width: 31.6%;
  height: 12.3rem;
  z-index: 2;
  max-height: 22rem;
  p {
    padding: 0.2rem;
    :hover {
      font-weight: bold;
    }
    cursor: pointer;
  }
  ${media.md`
    height: 24rem;
  `};
`

const scrollCarta = (containerId: string) => {
  scroller.scrollTo(containerId, {
    duration: 1500,
    delay: 100,
    smooth: true,
    offset: -146,
  })
}

const scrollmenuFinDesemana = () => {
  return scrollCarta('menuFinDesemana')
}

const scrollPescados = () => {
  return scrollCarta('pescados')
}

const scrollPostres = () => {
  return scrollCarta('postres')
}

const scrollBodega = () => {
  return scrollCarta('menus-especiales')
}

export default ({ data, location }: IndexPageProps) => {
  return (
    <>
      <Wrapper>
        <GlobalStyles />
        <Head pathname={location.pathname} />
        <ThemeProvider theme={theme}>{data}</ThemeProvider>
        <BarraNavegacion />
        <WrapperBody>
          <CabeceraLaCarta id="caja-derecha">
            <ColumnaCabeceraCarta>
              <ContenedorLeft>
                <p onClick={() => scrollmenuFinDesemana()}>
                  Menú fin de semana
                </p>
                <p onClick={() => scrollPescados()}>Menú degustación</p>
                <p onClick={() => scrollPostres()}>Menú del día</p>
                <p onClick={() => scrollBodega()}>Menús especiales</p>
              </ContenedorLeft>
            </ColumnaCabeceraCarta>
            <CajaDerecha>
              <Element name="menuFinDesemana">
                <MenuFinDeSemana key="carnes" />
              </Element>
              <div id="pescados">
                <Element name="pescados">
                  <MenuDegustacion key="pescados" />
                </Element>
              </div>
              <div id="postres">
                <Element name="postres">
                  <MenuDelDia key="postres" />
                </Element>
              </div>
              <div id="menus-especiales">
                <Element name="menus-especiales">
                  <MenusEspeciales key="menus-especiales" />
                </Element>
              </div>
            </CajaDerecha>
          </CabeceraLaCarta>
        </WrapperBody>

        <PieDePagina />
      </Wrapper>
    </>
  )
}
